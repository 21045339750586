import c from './card.module.scss';
import React from 'react';

const Card = ({ children, columnNos = 1 }) => {
  return (
    <div
      tabIndex="0"
      className={c.card}
      style={{ gridTemplateColumns: `repeat(${columnNos}, 1fr )` }}
    >
      {children}
    </div>
  );
};

export default Card;
