import { Button, Loading } from '@monash/portal-react';
import c from './inline-form.module.scss';

const InlineForm = ({
  setInEdit,
  children,
  submitHandler,
  isSubmitting,
  isSubmitEnabled = false,
  isLoading,
}) => {
  return (
    <div className={c.inlineForm}>
      <Loading isLoading={isLoading}>
        {children}
        <div className={c.actions}>
          <Button
            type="text"
            size="small"
            onClick={() => setInEdit(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="medium"
            onClick={submitHandler}
            loading={isSubmitting}
            disabled={!isSubmitEnabled}
          >
            Save
          </Button>
        </div>
      </Loading>
    </div>
  );
};

export default InlineForm;
