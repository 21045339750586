import courseEnrolmentStatusProps from '../data/course-enrolment-status-props.json';

const compareFn = (a, b) => {
  // A has a more relevant enrolment status
  if (
    courseEnrolmentStatusProps[a.status].relevanceIndex <
    courseEnrolmentStatusProps[b.status].relevanceIndex
  ) {
    return -1;
  }

  // B has a more relevant enrolment status
  if (
    courseEnrolmentStatusProps[a.status].relevanceIndex >
    courseEnrolmentStatusProps[b.status].relevanceIndex
  ) {
    return 1;
  }

  // A and B have the same enrolment status and A has a more recent enrolment date
  if (a.enrolmentDate > b.enrolmentDate) {
    return -1;
  }

  // A and B have the same enrolment status and B has a less recent enrolment date
  if (a.enrolmentDate < b.enrolmentDate) {
    return 1;
  }

  // A - Z
  return a.title.localeCompare(b.title);
};

/**
 * sortCourseEnrolmentsByRelevance
 * @param {array} enrolments - list of enrolment data
 * @returns {null|array} custom sorted list of enrolment data
 */
export const sortCourseEnrolmentsByRelevance = (enrolments = null) => {
  if (!Array.isArray(enrolments)) {
    console.error('Invalid enrolments list data:', enrolments);
    return null;
  }
  return [...enrolments].sort(compareFn);
};
